import { ICheckoutRequest, IManageSubRequest } from "types/subscription.model";
import { authorizedRequest } from "./request";

const subscriptionServices = {
  checkoutSubscription: (
    body: ICheckoutRequest
  ): Promise<{ redirect_url: string }> =>
    authorizedRequest.post("/api/payments/checkout/", { ...body }),
  manageSubscription: (
    body: IManageSubRequest
  ): Promise<{ redirect_url: string }> =>
    authorizedRequest.post("/api/payments/manage/", { ...body })
};

export default subscriptionServices;
