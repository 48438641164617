import { IChatHistoryResponse } from "types/ai-assistant.model";
import { authorizedRequest } from "./request";

const getSuggestivePromptList = (id: number): Promise<string[]> =>
  authorizedRequest.get(`api/analyzes/analysis/${id}/prompt-options`);

const getChatHistory = (
  versionId: number,
  offset?: number,
  limit?: number
): Promise<IChatHistoryResponse> =>
  authorizedRequest.get(`/api/data-sources/ai-chat-history/`, {
    params: {
      limit: limit || 10,
      offset: offset || 0,
      version: versionId
    }
  });

const editUserPrompt = (
  prompt: string,
  historyId: number
): Promise<{ answer: string }> =>
  authorizedRequest.post("/api/data-sources/ai-chat/regenerate-prompt/ ", {
    prompt,
    history: historyId
  });

const sendPrompt = (
  prompt: string,
  versionId: number
): Promise<{ answer: string; history_id: number }> =>
  authorizedRequest.post("/api/data-sources/ai-chat/", {
    prompt,
    version: versionId,
    is_testing: false
  });

const deletePrompt = (history: number): Promise<void> =>
  authorizedRequest.delete(`/api/data-sources/ai-chat-history/${history}/`);

const aiAssistantServices = {
  getSuggestivePromptList,
  getChatHistory,
  editUserPrompt,
  sendPrompt,
  deletePrompt
};

export default aiAssistantServices;
