import { IMember, IPermission, IInvitationCheck } from "types/member.model";
import { authorizedRequest } from "./request";

export const sendInvitations = (body: { email: string }[]): Promise<IMember> =>
  authorizedRequest.post("/api/members/invitations/", [...body]);

export const changeVersionPermission = (
  versionId: number,
  body: { email: string; view_permission: boolean }
): Promise<IPermission> =>
  authorizedRequest.patch(
    `/api/members/analysis-version-permissions/${versionId}/change/`,
    { ...body }
  );

export const changeAllPermission = (
  analysisId: number,
  body: { email: string; all_versions_view_permission: boolean }
): Promise<IPermission> =>
  authorizedRequest.patch(
    `/api/members/analysis-permissions/${analysisId}/change/`,
    { ...body }
  );

export const deleteInvitation = (id: number): Promise<void> =>
  authorizedRequest.delete(`/api/members/invitations/${id}/`);

export const validateToken = (token: string): Promise<IInvitationCheck> =>
  authorizedRequest.get(`/api/members/invitation-check/${token}/`);

export const leaveInvitation = (id: number): Promise<void> =>
  authorizedRequest.delete(`/api/members/accepted-invites/${id}/`);

const memberServices = {
  sendInvitations: (body: { email: string }[]): Promise<IMember> =>
    authorizedRequest.post("/api/members/invitations/", [...body]),
  changeVersionPermission: (
    versionId: number,
    body: { email: string; view_permission: boolean }
  ): Promise<IPermission> =>
    authorizedRequest.patch(
      `/api/members/analysis-version-permissions/${versionId}/change/`,
      { ...body }
    ),
  changeAllPermission: (
    analysisId: number,
    body: { email: string; all_versions_view_permission: boolean }
  ): Promise<IPermission> =>
    authorizedRequest.patch(
      `/api/members/analysis-permissions/${analysisId}/change/`,
      { ...body }
    ),
  deleteInvitation: (id: number): Promise<void> =>
    authorizedRequest.delete(`/api/members/invitations/${id}/`),
  validateToken: (token: string): Promise<IInvitationCheck> =>
    authorizedRequest.get(`/api/members/invitation-check/${token}/`),
  leaveInvitation: (id: number): Promise<void> =>
    authorizedRequest.delete(`/api/members/accepted-invites/${id}/`)
};

export default memberServices;
