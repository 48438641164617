import {
  ICalculateRequest,
  ICalculateResponse
} from "types/version-data.model";
import { authorizedRequest } from "./request";

const versionServices = {
  createCalculation: (
    versionId: number,
    body: ICalculateRequest
  ): Promise<ICalculateResponse> =>
    authorizedRequest.patch(`/api/analyzes/versions/${versionId}/calculate/`, {
      ...body
    })
};

export default versionServices;
