import {
  IAddChildRequest,
  IAddChildResponse,
  IRemoveChildRequest,
  IRemoveChildResponse
} from "types/child-logic.model";
import { authorizedRequest } from "./request";

const logicServices = {
  addChildLogic: (params: IAddChildRequest): Promise<IAddChildResponse> =>
    authorizedRequest.get(`/api/analyzes/fields/logic-changed/`, { params }),
  removeChildLogic: (
    params: IRemoveChildRequest
  ): Promise<IRemoveChildResponse> =>
    authorizedRequest.get(`/api/analyzes/fields/logic-changed/`, { params })
};

export default logicServices;
